<template>
  <div v-if="list.length">
    <modal-delete-snack
      @confirm="deleteAllChecks(list)"
      :isDataFields="isDataFields"
    />
    <div @mouseup="verifyIfDisabled($event)">
      <nested-draggable
        v-if="forceRenderTreeDraggable"
        :device="device"
        :list="list"
        :dragList="dragList"
        :dragListStart="dragListStart"
        :optionIcons="optionIcons"
        :levelIcons="levelIcons"
        :rolesList="rolesList"
        :shouldUpdate="shouldUpdate"
        :responsibleList="responsibleList"
        :isStructure="isStructure"
        :isMaterials="isMaterials"
        :isDataFields="isDataFields"
        :exhibitionLocationList="exhibitionLocationList"
        @disableDrag="disableDrag"
        @forceRenderTreeComponentsToggle="forceRenderTreeComponentsToggle"
        @undoChangesTree="undoChangesTree"
        @saveActualTree="saveActualTree"
        @checksCounter="countChecked(list)"
        @isAllChildrenChecked="isAllChildrenChecked"
        @allChecksToFalse="(arr) => allChecksToFalse(arr)"
        @updatedList="(list, id, destinyId) => updatedList(list, id, destinyId)"
        @getEditInfos="(id, level, type, parentId) => getEditInfos(id, level, type, parentId)"
      />
    </div>
    <div
      v-show="selectedChecks > 0 && device !== 'mobile'"
      class="float-bar"
      id="float-bar"
    >
      <span class="close-caption">
        <span>{{ selectedChecks }}</span>
        <span>{{ $t('DepartmentPage.SelectedItems') }}</span>
      </span>
      <div class="box-btn">
        <button
          class="btn-cancel btn-snack"
          @click="clearAllChecks(list)"
        >
          {{ $t('IntegrationPage.Clean') }}
        </button>

        <button
          class="btn-delete btn-snack"
          @click="UPDATE_MODAL_DELETE_SNACK_BAR(true)"
        >
          {{ $t('IntegrationPage.delete') }}
        </button>
      </div>
    </div>
  </div>
  <div
    v-else
    class="block-no-results"
  >
    <div class="no-results">
      <SearchIcon />
      <p class="text">{{ $t('NoResultsFound') }}</p>
    </div>
  </div>
</template>

<script>
  import nestedDraggable from './NestedDraggable.vue';
  import ModalDeleteSnack from './ModalDeleteSnack.vue';
  import { mapMutations } from 'vuex';
  import ToastificationContent from '../toastification/ToastificationContent.vue';

  export default {
    name: 'CollapseDraggable',
    display: 'Nested',
    order: 15,
    components: {
      nestedDraggable,
      ModalDeleteSnack,
      SearchIcon: () => import('@core/assets/icons/search-icon.svg')
    },
    props: {
      list: {
        type: Array,
        default: []
      },
      rolesList: {
        type: Array
      },
      responsibleList: {
        type: Array
      },
      optionIcons: {
        type: Array
      },
      exhibitionLocationList: {
        type: Array
      },
      levelIcons: {
        type: Array
      },
      dragList: {
        type: Array
      },
      dragListStart: {
        type: Array
      },
      shouldUpdate: {
        type: Boolean
      },
      isStructure: {
        type: Boolean,
        default: false
      },
      isMaterials: {
        type: Boolean,
        default: false
      },
      isDataFields: {
        type: Boolean,
        default: false
      },
      selectedChecks: {
        type: Number,
        default: 0
      },
      forceRender: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        forceRenderTreeDraggable: true,
        idsToDeleteDep: [],
        level: 1,
        objToDelete: [],
        isDragDisable: false,
        device:
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile'
      };
    },
    created() {
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      ...mapMutations('departments', ['UPDATE_MODAL_DELETE_SNACK_BAR']),
      clearAllChecks(list) {
        list.filter((item) => {
          if (item.checked) {
            item.checked = false;
          }
          if (item.child.length > 0) {
            this.clearAllChecks(item.child);
          }
        });
        this.forceRenderTreeComponentsToggle();
      },
      saveActualTree() {
        this.$emit('saveActualTree');
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      verifyIfDisabled(elem) {
        const hasCheckedItems = this.checkIfCheckedExists(this.dragList);
        if (this.isDragDisable && hasCheckedItems) {
          const isNoActionDefault = elem.target.closest('.__no-action-default');
          if (isNoActionDefault) return;

          let toastText = this.$t('DepartmentPage.ErrorToastText');

          if (this.isStructure) {
            toastText = this.$t('StructurePage.ErrorToastText');
          }

          if (this.isMaterials) {
            toastText = this.$t('MaterialsPage.ErrorToastText');
          }

          if (this.isDataFields) {
            toastText = this.$t('DataFieldsPage.ErrorToastText');
          }
          this.showToast(this.$t('Error'), 'FrownIcon', toastText, 'danger');
        }
      },
      disableDrag(v) {
        this.isDragDisable = v;
      },
      checkIfCheckedExists(list) {
        let checkedExists = false;

        for (const item of list) {
          if (item.checked) {
            checkedExists = true;
            break;
          }

          if (item.child.length > 0) {
            checkedExists = this.checkIfCheckedExists(item.child);
            if (checkedExists) {
              break;
            }
          }
        }

        return checkedExists;
      },
      updatedList(list, id, destinyId) {
        this.$emit('updatedList', list, id, destinyId);
      },
      getEditInfos(id, level, type, parentId) {
        this.$emit('getEditInfos', id, level, type, parentId);
      },
      undoChangesTree() {
        this.$emit('undoChangesTree');
        const element = document.getElementById('float-bar');
        if (element && this.selectedChecks > 0) element.style.display = 'flex';
      },
      deleteAllChecks(list) {
        this.idsToDeleteDep = [];
        this.objToDelete = [];
        this.getIdsToDelete(list, this.level);
        this.$emit('deleteDepartments', this.idsToDeleteDep);
        this.$emit('deleteItems', this.objToDelete);
      },
      getIdsToDelete(list, level) {
        list.map((e) => {
          if (e.checked) {
            this.objToDelete.push({
              level: level,
              id: e.id,
              type: e.type,
              parentId: e.parentId ? e.parentId : null,
              defaultRoute: level === 2 ? e.default : null
            });
            this.idsToDeleteDep.push(e.id);
          }
          if (e.child.length > 0) {
            this.getIdsToDelete(e.child, level + 1);
          }
        });
      },
      allChecksToFalse(arr) {
        this.isDragDisable = false;
        for (const obj of arr) {
          obj['checked'] = false;
          if (obj.child && obj.child.length > 0) {
            this.allChecksToFalse(obj.child);
          }
        }
        this.$emit('SelectedToZero');
        const element = document.getElementById('float-bar');
        element.style.display = 'none';
      },
      isAllChildrenChecked() {
        this.levelIcons.forEach((level) => {
          this.uncheckedParent(this.dragList);
        });
        this.forceRenderTreeComponentsToggle();
      },
      uncheckedParent(list) {
        list.forEach((item) => {
          const allCheckedChildren = item.child.every((child) => child.checked);
          const allUnCheckedChildren = item.child.every((child) => !child.checked);
          if (allCheckedChildren) {
            item['checked'] = item.checked;
          } else if (allUnCheckedChildren) {
            item['checked'] = false;
          } else {
            item['checked'] = false;
          }

          if (item.child) this.uncheckedParent(item.child);
        });
      },
      countChecked(obj) {
        let count = 0;
        for (let i = 0; i < obj.length; i++) {
          if (obj[i].checked) {
            count++;
          }
          if (obj[i].child.length > 0) {
            count += this.countChecked(obj[i].child);
          }
        }
        return count;
      },
      handleResize() {
        this.device =
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile';
      },
      forceRenderTreeComponentsToggle() {
        this.forceRenderTreeDraggable = false;
        this.$nextTick().then(() => {
          this.forceRenderTreeDraggable = true;
          this.$emit('checkedCounter', this.countChecked(this.list));
        });
      }
    },
    watch: {
      forceRender() {
        this.forceRenderTreeComponentsToggle();
      }
    }
  };
</script>

<style lang="scss">
  .float-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px;

    background: #ffffff;
    border: 1px solid #cfc4be;
    border-radius: 6px;
    box-shadow:
      0px 3px 5px 0px rgba(0, 0, 0, 0.2),
      0px 1px 18px 0px rgba(0, 0, 0, 0.12),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14);

    position: fixed;
    bottom: 20px;
    z-index: 10;
    font-size: 12px;
    outline: none;
    right: 28px;
    width: 100%;
    max-width: 531px;

    .close-caption {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #4c4541;
      margin-right: auto;
    }

    .box-btn {
      display: flex;
      gap: 16px;

      .btn-snack {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-delete {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }

  .block-no-results {
    height: 100%;
    padding: 8px 16px 0;
    .no-results {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      border-radius: 6px;
      border: 1px solid #cfc4be;

      svg {
        width: 16px;
        height: 16px;
        fill: #998f8a;
      }

      .text {
        margin: 0;
        color: #998f8a;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
      }

      @media (max-width: 480px) {
        svg {
          width: 12px;
          height: 12px;
        }
        .text {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .float-bar {
      right: 0;
      left: 0;
      width: calc(100% - 58px);
      margin: 0 auto;
      gap: 16px !important;
      max-width: unset !important;

      .filtered-orders {
        font-size: 18px;
      }

      .box-btn {
        .btn-clean {
          height: 46px !important;
        }
      }
    }
  }
</style>
